<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Merchant Rewards</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <v-btn-toggle class="col-12 col-md-6 offset-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search" @keyup.enter="searchTimeOut"></v-text-field>
                </v-btn-toggle>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Operator"
                    label="Operator"
                    v-model="operator.selected"
                    @change="execFilter('operator')"
                    :items="operator.items"
                    item-text="name"
                    item-value="OperatorID"
                    :rules="operator.rule"
                    :loading="operator.loader"
                    :disabled="operator.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Car Parks"
                    label="Car Park"
                    v-model="carpark.selected"
                    @change="execFilter('carpark')"
                    :items="carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="carpark.rule"
                    :loading="carpark.loader"
                    :disabled="carpark.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
                <v-col cols="12" md="4">
                    <v-checkbox
                    :key="displaystatus.rerender"
                    indeterminate
                    value
                    class="mt-4 float-md-right"
                    v-model="displaystatus.selected"
                    @change="execFilter('displaystatus')"
                    :label="displaystatus.label"
                    hide-details
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('merchantrewards_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create Merchant Reward
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12" id="table-parent">
                    <div id="wrapper1">
                        <div id="div1">
                        </div>
                    </div>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.CorporateAccountID}}</td>
                            <td>{{props.item.CorporateName}}</td>
                            <td>{{props.item.OperatorID}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkUserInputCode}}</td>
                            <td style="white-space: pre">{{props.item.CarParkName}}</td>
                            <td>{{props.item.SlotGroupID}}</td>
                            <td>
                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <a v-on="on" @click="update(props.item)" style="text-decoration: underline" id="custom-hover">{{props.item.SlotID}}</a>
                                    </template>
                                    <span>{{validateAccess('merchantrewards_update') ? "Update" : "View"}} Merchant Reward</span>
                                </v-tooltip>
                            </td>
                            <td style="white-space: pre">{{props.item.Ad_Title}}</td>
                            <td>{{props.item.PurchaseDurationPerSlot}}</td>
                            <td><span style="text-transform: capitalize">{{props.item.PurchaseDurationUnit}}</span><span>(s)</span></td>
                            <td>{{props.item.Ad_Start_Date}}</td>
                            <td>{{props.item.Ad_End_Date}}</td>
                            <td>{{statusCheck(props.item.HasRedeemablePromo, 'text')}}</td>
                            <td>{{props.item.ItemGrandTotal}}</td>
                            <td>{{props.item.DateCreated}}</td>
                            <td>{{statusCheck(props.item.MobileDisplayStatus, 'text')}}</td>
                            <td style="text-transform: capitalize;">{{props.item.Approval_Status}}</td>
                            <td>
                                <img v-if="props.item.Ad_Image_1 !== null" class="my-2" style="border: 1px solid #191716" width="120px" :src="props.item.Ad_Image_1" />
                                <span v-else>N/A</span>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed" :operator="operator.itemsFull" :carpark="carpark.itemsFull" :productcode="productcode.itemsFull"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :operator="operator.itemsFull" :carpark="carpark.itemsFull" :productcode="productcode.itemsFull" :allInfo="ma.allInfo" @delete="onDelete" @confirm="onConfirm" @closed="onClosed"></edit>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateMerchantRewardSlot';
import edit from '.././modals/UpdateMerchantRewardSlot';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';
import * as AppStorage from '../../assets/js/storage';

export default {
    name: 'Merchant Rewards',
    title: 'Merchant Rewards',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Marketing',
                disabled: true,
                href: '#',
                },

                {
                text: 'Merchant Reward',
                disabled: true,
                href: '#',
                },

                {
                text: 'Merchant Rewards',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            /*
            #, Account ID, Corporate, CPO ID, CP ID, CP Code, Car Park, Slot Group ID, Slot ID, Rewards Name, Reward Start Date, Reward End Date, Promo Voucher, Amount (RM), Date Created, Status
            */
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Account ID', value: 'CorporateAccountID', width: '3%' },
                { text: 'Corporate', value: 'CorporateName', width: '3%' },
                { text: 'CPO ID', value: 'OperatorID', width: '3%' },
                { text: 'CP ID', value: 'CarParkID', width: '3%' },
                { text: 'CP Code', value: 'CarParkUserInputCode', width: '3%' },
                { text: 'Car Park', value: 'CarParkName', width: '3%' },
                { text: 'Slot Group ID', value: 'SlotGroupID', width: '3%' },
                { text: 'Slot ID', value: 'SlotID', width: '3%' },
                { text: 'Merchant Reward Name', value: 'Ad_Title', width: '3%' },
                { text: 'Slot Duration', value: 'PurchaseDurationPerSlot', width: '3%' },
                { text: 'Slot Type', value: 'PurchaseDurationUnit', width: '3%' },
                { text: 'Merchant Reward Start Date', value: 'Ad_Start_Date', width: '12%' },
                { text: 'Merchant Reward End Date', value: 'Ad_End_Date', width: '12%' },
                { text: 'Promo Voucher', value: 'HasRedeemablePromo', width: '7%' },
                { text: 'Total Amount (RM)', value: 'ItemGrandTotal', width: '7%' },
                { text: 'Created Date', value: 'DateCreated', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Mobile Display', value: 'MobileDisplayStatus', width: '7%' },
                { text: 'Status', value: 'Approval_Status', width: '7%' },
                { text: 'Image', value: 'Ad_Image_1', width: '7%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            productcode: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            displaystatus: {
                counter: 0,
                selected: null,
                rerender: 0,
                label: 'All Mobile Display Status'
            },

            services: [],
            totalrecords: ''
        }
    },

    components: {create, edit, alert, alertbox2},

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.listAll();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

                else{
                    return '';
                }
            },
    },

    async created(){
        this.$vuetify.goTo(0);
        await this.getOperatorFull();
        await this.getCarParkFull();
        window.addEventListener("unload", (evt) => this.onUnload({operator: this.operator.selected, carpark: this.carpark.selected}, evt));
        this.operator.selected = JSON.parse(AppStorage.get('filter')).operator;
        this.carpark.selected = JSON.parse(AppStorage.get('filter')).carpark;
        await this.listAll(1);
        this.horizontalScroll();
    },

    methods: {
        async listAll(pg){
            try{
                this.tableloading = true;
                //let slotid = this.$route.query.slotid == undefined ? "" : this.$route.query.slotid;
                //let slotgroupid = this.$route.query.slotgroupid == undefined ? "" : this.$route.query.slotgroupid;
                //let loginemailid = this.$route.query.id == undefined ? "" : this.$route.query.id;
                let slotid = this.$route.query.slotid == undefined ? "" : "";
                let slotgroupid = this.$route.query.slotgroupid == undefined ? "" : "";
                let loginemailid = this.$route.query.id == undefined ? "" : "";
                this.carpark.selected = this.$route.query.carparkid == undefined ? this.carpark.selected : this.$route.query.carparkid;
                this.operator.selected = this.$route.query.operatorid == undefined ? this.operator.selected : this.$route.query.operatorid;
                let response = await this.$store.dispatch("getMerchantRewardSlot", {page: pg > 0 ? this.page = pg : this.page, search: this.search, operatorid: this.operator.selected, carparkid: this.carpark.selected, mobiledisplaystatus: this.displaystatus.selected, slotid: slotid, slotgroupid: slotgroupid, loginemailid: loginemailid});
                let response2 = await this.$store.dispatch("getAllCarpark2", {});

                this.services = response;
                if (response.data.code == 'AP000'){

                    if (this.$route.query.created == true){
                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Merchant Reward Created!`;
                    }

                    if (this.$route.query.updated == true){
                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        if (this.$route.query.appstatus == 'update')
                            this.swal.message = `${ERR.HANDLE(response.data.code)}: This Reward Slot is Updated!`;
                            
                        else
                            this.swal.message = `${ERR.HANDLE(response.data.code)}: This post is now set to ${this.$route.query.appstatus}!`;
                    }

                let dt = response.data.record;
                let dt2 = response2.data.record;
                let recordCount = response.data.recordcount[0].resultcount;
                this.totalrecords = recordCount;
                const events = [];

                dt.forEach((doc, i) => {
                    let appData = doc;
                    appData.row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    appData.StartDateFromAPI = doc.PromoUseStartDate == null ? "N/A" : doc.PromoUseStartDate;
                    appData.EndDateFromAPI = doc.PromoUseEndDate == null ? "N/A" : doc.PromoUseEndDate;

                    appData.PromoUseStartDate = doc.PromoUseStartDate == null ? "N/A" : moment.utc(dt[i].PromoUseStartDate).tz('Asia/Kuala_Lumpur').format('DD-MM-YYYY');
                    appData.PromoUseEndDate = doc.PromoUseEndDate == null ? "N/A" : moment.utc(dt[i].PromoUseEndDate).tz('Asia/Kuala_Lumpur').format('DD-MM-YYYY');

                    appData.Ad_Start_Date = doc.Ad_Start_Date == null ? "N/A" : moment.utc(dt[i].Ad_Start_Date).tz('Asia/Kuala_Lumpur').format('DD-MM-YYYY');
                    appData.Ad_End_Date = doc.Ad_End_Date == null ? "N/A" : moment.utc(dt[i].Ad_End_Date).tz('Asia/Kuala_Lumpur').format('DD-MM-YYYY');

                    appData.DateCreated = doc.DateCreated == null ? "N/A" : moment(new Date(doc.DateCreated)).format('DD-MM-YYYY hh:mm:ss A');

                    appData.ItemGrandTotal = doc.ItemGrandTotal == null || doc.ItemGrandTotal == undefined ? "N/A" : Number(doc.ItemGrandTotal).toFixed(2);
                    appData.AccountID = doc.AccountID == null || doc.AccountID == undefined ? "N/A" : doc.AccountID;

                    appData.CostPerSlot = Number(appData.CostPerSlot).toFixed(2);

                    let a = dt2.find(itm => itm.CarParkID == doc.CarParkID);
                    appData.CarParkName = a.CarParkName;
                    appData.CarParkUserInputCode = a.CarParkUserInputCode;
                    appData.Ad_Title = doc.Ad_Title == null ? "N/A" : doc.Ad_Title;
                    appData.Approval_Status = doc.Approval_Status == null ? "New" : doc.Approval_Status;
                    appData.CorporateName = doc.CorporateName == null ? "N/A" : doc.CorporateName;
                    
                    events.push(appData);
                })

                this.items = events;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;

                if (this.$route.query.cpid || this.$route.query.id) this.$router.replace({'query': null});
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operators", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                if (this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    this.carpark.items.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                        this.carpark.itemsFull.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.carpark.itemsFull.push(dt[i]);
                        }
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async addOperator(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {
                    slotgroupname: v.slotname.item,
                    carparkid: v.carpark.selected,
                    startdate: v.startdate.itemforapi,
                    enddate: v.enddate.itemforapi,
                    totalslots: v.totalqty.item,
                    purchasedurationperslot: v.duration.item,
                    purchasedurationunit: v.slottype.selected,
                    costperslot: v.unitprice.item
                };
                let response = await this.$store.dispatch("createMerchantRewardSlotGroup", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.listAll(1);
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Merchant Slot Group Created!`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
        },

        async operatorRemove(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("deleteSPFee", {spfeeid: this.ma.modalInfo.ID})
                if (response.data.code === 'AP000'){
                        await this.listAll(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Remittance Finance has been removed!`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async operatorUpdate(v){
            console.log('here', v);
            let req = {
                slotgroupid: this.ma.modalInfo.SlotGroupID,
                slotgroupname: v.slotname.item,
                carparkid: v.carpark.selected,
                startdate: v.startdate.itemforapi,
                enddate: v.enddate.itemforapi,
                totalslots: v.totalqty.item,
                purchasedurationperslot: v.duration.item,
                purchasedurationunit: v.slottype.selected,
                costperslot: v.unitprice.item
            };

            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("updateMerchantRewardSlotGroup", req)
                if (response.data.code === 'AP000'){
                        this.items = [];
                        await this.listAll(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Merchant Slot Group has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.$router.push({ name: "Create Merchant Rewards"});
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        update(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Update Merchant Rewards", params: {id: row.SlotID, loginemailid: row.loginEmailID}});
        },

        viewProfile(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin Profile", params: {id: row.userID}, query: {email: row.email}});
        },

        viewSOC(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin SOC", params: {id: row.userID}, query: {email: row.email}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
            }
        },

        onConfirm(value){
            console.log('aa', value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addOperator(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.operatorUpdate(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.operatorRemove();
                }
            }
        },

        onDelete(){
            this.ma.modal_update = false;
            this.operatorRemove();
        },

        async execFilter(type){
            if (type == 'operator'){
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'carpark'){
                await this.searchTimeOut();
            }

            if (type == 'displaystatus'){
                this.displaystatus.counter++;
                if (this.displaystatus.counter % 3 == 0){
                    this.displaystatus.rerender++;
                    this.displaystatus.selected = null;
                    this.displaystatus.label = "All Mobile Display Status";
                }

                if (this.displaystatus.selected === true){
                    this.displaystatus.label = "Mobile Display Status Enable";
                }

                if (this.displaystatus.selected === false){
                    this.displaystatus.label = "Mobile Display Status Disable";
                }
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listAll(1);
          }, 800);
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Yes'}
                    else if (val === 0){return 'No'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },

        mobileDisplayStatus(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Yes'}
                    else if (val === 0){return 'No'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },

        onUnload(filter, evt){
            AppStorage.set('filter', JSON.stringify(filter));
        },

        horizontalScroll(){
            var wrapper1 = document.getElementById('wrapper1');
            var wrapper2 = document.querySelector('.v-data-table__wrapper');
            document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
            document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

            if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";

            window.addEventListener('resize', function() {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });

            const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;
                console.log('table', document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth);
                console.log('parent', document.getElementById("table-parent").offsetWidth);

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });
            });

            const someEl = document.getElementsByTagName("table")[0];
            myObserver.observe(someEl);


            wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }
    }
}
</script>